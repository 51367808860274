// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-achievements-js": () => import("./../../../src/pages/achievements.js" /* webpackChunkName: "component---src-pages-achievements-js" */),
  "component---src-pages-allbrands-js": () => import("./../../../src/pages/allbrands.js" /* webpackChunkName: "component---src-pages-allbrands-js" */),
  "component---src-pages-brands-js": () => import("./../../../src/pages/brands.js" /* webpackChunkName: "component---src-pages-brands-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-category-category-js": () => import("./../../../src/pages/category/category.js" /* webpackChunkName: "component---src-pages-category-category-js" */),
  "component---src-pages-category-gears-agriculture-js": () => import("./../../../src/pages/category/gears/agriculture.js" /* webpackChunkName: "component---src-pages-category-gears-agriculture-js" */),
  "component---src-pages-category-gears-automotive-js": () => import("./../../../src/pages/category/gears/automotive.js" /* webpackChunkName: "component---src-pages-category-gears-automotive-js" */),
  "component---src-pages-category-gears-industrial-js": () => import("./../../../src/pages/category/gears/industrial.js" /* webpackChunkName: "component---src-pages-category-gears-industrial-js" */),
  "component---src-pages-category-gears-js": () => import("./../../../src/pages/category/gears.js" /* webpackChunkName: "component---src-pages-category-gears-js" */),
  "component---src-pages-category-productdetail-js": () => import("./../../../src/pages/category/productdetail.js" /* webpackChunkName: "component---src-pages-category-productdetail-js" */),
  "component---src-pages-category-splineshaft-agriculture-js": () => import("./../../../src/pages/category/splineshaft/agriculture.js" /* webpackChunkName: "component---src-pages-category-splineshaft-agriculture-js" */),
  "component---src-pages-category-splineshaft-automotive-js": () => import("./../../../src/pages/category/splineshaft/automotive.js" /* webpackChunkName: "component---src-pages-category-splineshaft-automotive-js" */),
  "component---src-pages-category-splineshaft-js": () => import("./../../../src/pages/category/splineshaft.js" /* webpackChunkName: "component---src-pages-category-splineshaft-js" */),
  "component---src-pages-category-sprockets-agriculture-js": () => import("./../../../src/pages/category/sprockets/agriculture.js" /* webpackChunkName: "component---src-pages-category-sprockets-agriculture-js" */),
  "component---src-pages-category-sprockets-automotive-js": () => import("./../../../src/pages/category/sprockets/automotive.js" /* webpackChunkName: "component---src-pages-category-sprockets-automotive-js" */),
  "component---src-pages-category-sprockets-industrial-js": () => import("./../../../src/pages/category/sprockets/industrial.js" /* webpackChunkName: "component---src-pages-category-sprockets-industrial-js" */),
  "component---src-pages-category-sprockets-js": () => import("./../../../src/pages/category/sprockets.js" /* webpackChunkName: "component---src-pages-category-sprockets-js" */),
  "component---src-pages-category-sprockets-machinery-js": () => import("./../../../src/pages/category/sprockets/machinery.js" /* webpackChunkName: "component---src-pages-category-sprockets-machinery-js" */),
  "component---src-pages-category-sprockets-textile-js": () => import("./../../../src/pages/category/sprockets/textile.js" /* webpackChunkName: "component---src-pages-category-sprockets-textile-js" */),
  "component---src-pages-category-subcategory-js": () => import("./../../../src/pages/category/subcategory.js" /* webpackChunkName: "component---src-pages-category-subcategory-js" */),
  "component---src-pages-company-about-js": () => import("./../../../src/pages/company/about.js" /* webpackChunkName: "component---src-pages-company-about-js" */),
  "component---src-pages-company-history-js": () => import("./../../../src/pages/company/history.js" /* webpackChunkName: "component---src-pages-company-history-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-enquire-js": () => import("./../../../src/pages/enquire.js" /* webpackChunkName: "component---src-pages-enquire-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infra-js": () => import("./../../../src/pages/infra.js" /* webpackChunkName: "component---src-pages-infra-js" */),
  "component---src-pages-quality-assurance-js": () => import("./../../../src/pages/quality-assurance.js" /* webpackChunkName: "component---src-pages-quality-assurance-js" */),
  "component---src-pages-quality-js": () => import("./../../../src/pages/quality.js" /* webpackChunkName: "component---src-pages-quality-js" */)
}

